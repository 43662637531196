import './order-form.scss';

import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useStepper,
  useOrderFormContext,
  useApplicationStatus,
  usePostalCodeService,
  useOrderQueryParams,
  useSettingsService,
  useProductType,
} from '../../hooks';
import { Formik, FormikErrors, FormikHelpers, yupToFormErrors } from 'formik';
import { Flex } from '../../styled-components';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';

import TooltipSvg from '../../assets/tooltip.svg';
import OrderStepperContext from '../../contexts/stepper/OrderStepperContext';
import { FuelProductName, DieselProductName, ProductTypes } from '../../assets/constants/Constants';
import { isErrorViewModel, OrderFormPostalCode, PostalCode, UnloadingPlace } from '../../models';
import withErrorHandling from '../common/hoc/with-error-handling/withErrorHandling';
import useErrorHandling from '../../hooks/useErrorHandling';
import useCurrentLanguage from '../../hooks/useCurrentLanguage';
import { useTextResourceService } from '../../hooks/services/useTextResourceService';
import { Language } from '../../assets/enums';
import i18next from '../../i18n';

/**
 * Slim version of the postal code interface for the form.
 */
interface FormPostalCode {
  npa: string;
  name: string;
}

/**
 * This interface represents the form values input by the user.
 */
interface OrderFormValues {
  postalCode: FormPostalCode;
  quantity: number;
  unloadingPlaces: number;
  unloadingPlaceItems: UnloadingPlace[];
}

/**
 * This interface describes all the possible props that can be passed
 * to our OrderForm component.
 */
interface OrderFormProps {
  type: ProductTypes;
}

/**
 * This is the form that allows you to choose what kind of order you want to process.
 * e.g.: order a certain amount of pellets that will be unloaded in 3 places in
 *       a certain postal code (city).
 */
const OrderForm = ({ type }: OrderFormProps): JSX.Element | null => {
  const { t } = useTranslation();

  const possibleUnloadingPlaces = 5;

  const { prevStep, nextStep } = useStepper(OrderStepperContext)!;

  const { errors: errorHandlingErrors, setErrors } = useErrorHandling();

  const { setApplicationStatus } = useApplicationStatus();

  const postalCodeService = usePostalCodeService();

  const textResourceService = useTextResourceService();

  const {
    postalCode,
    quantity,
    unloadingPlaces,
    unloadingPlaceItems,
    setPostalCode,
    setQuantity,
    setUnloadingPlaces,
    setUnloadingPlaceItems,
  } = useOrderFormContext();

  const settingsService = useSettingsService();
  const language = useCurrentLanguage();
  const productTypeString = useProductType();

  const orderQueryParams = useOrderQueryParams();

  const areQueryParamsPresent = useMemo(() => {
    return !!orderQueryParams.postalCode && !!orderQueryParams.quantity && !!orderQueryParams.unloadingPlaces;
  }, [orderQueryParams]);

  const [isLoading, setIsLoading] = useState<boolean>(areQueryParamsPresent);
  const [isVerifyingPostalCodeFieldName, setIsVerifyingPostalCodeFieldName] = useState<string>('');

  const [availablePostalCodesWithNpa, setAvailablePostalCodesWithNpa] = useState<PostalCode[]>([]);

  const [maxFuelQuantity, setMaxFuelQuantity] = useState<number>(0);
  const [maxWoodQuantity, setMaxWoodQuantity] = useState<number>(0);
  const [maxDieselQuantity, setMaxDieselQuantity] = useState<number>(0);
  const [minFuelQuantity, setMinFuelQuantity] = useState<number>(0);
  const [minWoodQuantity, setMinWoodQuantity] = useState<number>(0);
  const [minDieselQuantity, setMinDieselQuantity] = useState<number>(0);
  const [isLoadingSettings, setIsLoadingSettings] = useState<boolean>(false);
  const [isLoadingTexResources, setIsLoadingTexResources] = useState<boolean>(false);

  const emptyUnloadingPlace: UnloadingPlace = { npa: '', quantity: 0, name: '' };

  /**
   * This is the Yup schema that Formik is going to follow for validation.
   * It's a lot simpler than typing out manually Regexes for simple checks
   * like the length of the postal code. Plus, it works seamlessly with Formik
   * integrating error messages as well.
   */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const postalCodeSchema = {
    npa: Yup.string()
      .matches(/^[0-9]+$/, t('PostalCodeTypeErrorMessage'))
      .min(4, t('PostalCodeTooShortErrorMessage'))
      .max(4, t('PostalCodeTooLongErrorMessage'))
      .required(t('PostalCodeRequiredErrorMessage'))
      .test('postal-code-exists', t('PostalCodeDoesNotExist'), async (value, context) => {
        if (
          value?.length === 4 &&
          !isNaN(+value) &&
          !availablePostalCodesWithNpa.some((pc) => pc.npa.toString() === value)
        ) {
          setIsVerifyingPostalCodeFieldName(context.path);

          const apcWithNpa = await postalCodeService.getPostalCodesWithNpa(parseInt(value));

          setIsVerifyingPostalCodeFieldName('');

          return apcWithNpa.length >= 1;
        }
        return true;
      }),
  };

  const orderValidationSchema = useMemo(
    () =>
      Yup.object().shape({
        postalCode: Yup.object().shape({
          ...postalCodeSchema,
          name: Yup.string().required(t('PostalCodeLocationRequiredErrorMessage')),
        }),
        quantity: Yup.number()
          .min(
            type === FuelProductName
              ? minFuelQuantity
              : type == DieselProductName
              ? minDieselQuantity
              : minWoodQuantity,
            type === FuelProductName
              ? t('MinimumQuantityErrorMessageFuel', { minFuelQuantity: minFuelQuantity })
              : type == DieselProductName
              ? t('MinimumQuantityErrorMessageDiesel', { minDieselQuantity: minDieselQuantity })
              : t('MinimumQuantityErrorMessageWood', { minWoodQuantity: minWoodQuantity }),
          )
          .max(
            type === FuelProductName
              ? maxFuelQuantity
              : type == DieselProductName
              ? maxDieselQuantity
              : maxWoodQuantity,
            type === FuelProductName
              ? t('MaximumQuantityErrorMessageFuel', { maxFuelQuantity: maxFuelQuantity })
              : type == DieselProductName
              ? t('MaximumQuantityErrorMessageDiesel', { maxDieselQuantity: maxDieselQuantity })
              : t('MaximumQuantityErrorMessageWood', { maxWoodQuantity: maxWoodQuantity }),
          )
          .required(t('QuantityRequiredErrorMessage')),
        unloadingPlaces: Yup.number()
          .min(1, t('UnloadingPlacesTooLowErrorMessage'))
          .max(5, t('UnloadingPlacesTooManyErrorMessage'))
          .required(t('UnloadingPlacesRequiredErrorMessage')),
        unloadingPlaceItems: Yup.array()
          .of(
            Yup.object().shape({
              ...postalCodeSchema,
              name: Yup.string().required(t('PostalCodeLocationRequiredErrorMessage')),
              quantity: Yup.number()
                .min(
                  type === FuelProductName
                    ? minFuelQuantity
                    : type === DieselProductName
                    ? minDieselQuantity
                    : minWoodQuantity,
                  type === FuelProductName
                    ? t('MinimumQuantityErrorMessageFuel', { minFuelQuantity: minFuelQuantity })
                    : type === DieselProductName
                    ? t('MinimumQuantityErrorMessageDiesel', { minDieselQuantity: minDieselQuantity })
                    : t('MinimumQuantityErrorMessageWood', { minWoodQuantity: minWoodQuantity }),
                )
                .required(t('QuantityRequiredErrorMessage')),
            }),
          )
          .test(
            'unloadingplace-items-quantity',
            type === FuelProductName
              ? t('MaximumQuantityErrorMessageFuel', {
                  maxFuelQuantity: maxFuelQuantity,
                })
              : type === DieselProductName
              ? t('MaximumQuantityErrorMessageDiesel', {
                  maxDieselQuantity: maxDieselQuantity,
                })
              : t('MaximumQuantityErrorMessageWood', {
                  maxWoodQuantity: maxWoodQuantity,
                }),
            async (value, context) => {
              const unloadingPlacesTotalQty: number =
                context.parent?.unloadingPlaceItems?.reduce((sum, obj) => {
                  return sum + obj.quantity;
                }, 0) ?? 0;
              if (unloadingPlacesTotalQty > context.parent.quantity) {
                return context.createError({
                  path: 'quantity',
                  message: t('QuantityMismatch'),
                });
              }
              return true;
            },
          ),
      }),
    [
      postalCodeSchema,
      t,
      type,
      minFuelQuantity,
      minDieselQuantity,
      minWoodQuantity,
      maxFuelQuantity,
      maxDieselQuantity,
      maxWoodQuantity,
    ],
  );

  /**
   * Initial form values.
   */
  const initialValues = useMemo<OrderFormValues>(
    () => ({
      postalCode: postalCode ? { npa: postalCode.npa, name: postalCode.name ?? '' } : { npa: '', name: '' },
      quantity: quantity,
      unloadingPlaces: unloadingPlaces,
      unloadingPlaceItems:
        unloadingPlaceItems.length > 0
          ? unloadingPlaceItems
          : areQueryParamsPresent && orderQueryParams.unloadingPlaces
          ? Array.from({ length: Number(orderQueryParams.unloadingPlaces) }).map((value, index) => emptyUnloadingPlace)
          : [],
      errorMsg: '',
    }),
    [],
  );

  /**
   * Callback used for calculating the price for the order, by calling the API
   * and set the context values + proceed to next step in the stepper.
   */
  const submitOrderAndNextStep = useCallback(
    async (values: OrderFormValues, actions: FormikHelpers<OrderFormValues>) => {
      if (
        values.unloadingPlaceItems &&
        values.unloadingPlaceItems.length > 0 &&
        values.unloadingPlaceItems.reduce((sum, value) => sum + value.quantity, 0) !== values.quantity
      ) {
        actions.setFieldError('quantity', t('QuantityMismatch'));
        return;
      }
      settingsService.getSystemInfo(productTypeString, language, t).then((systemInfo) => {
        const selectedPostalCode = availablePostalCodesWithNpa.find((apc) => apc.name === values.postalCode.name);

        if (!selectedPostalCode) {
          setErrors([
            ...errorHandlingErrors,
            {
              statusCode: '',
              title: 'Unexpected error occurred.',
              value: {
                description: "Couldn't find selected postal code. Please, contact your administrator.",
              },
            },
          ]);

          return;
        }

        const orderPostalCode: OrderFormPostalCode = {
          npa: selectedPostalCode.npa.toString(),
          name: selectedPostalCode.name,
        };

        setPostalCode(orderPostalCode);
        setQuantity(values.quantity);
        setUnloadingPlaces(values.unloadingPlaces);
        setUnloadingPlaceItems(values.unloadingPlaceItems);

        // eslint-disable-next-line
        const { postalCode: formPostalCode, ...rest } = values;

        setApplicationStatus({
          postalCode: orderPostalCode,
          ...rest,
        });

        nextStep();
      });
    },
    [
      settingsService,
      productTypeString,
      language,
      t,
      availablePostalCodesWithNpa,
      setPostalCode,
      setQuantity,
      setUnloadingPlaces,
      setUnloadingPlaceItems,
      setApplicationStatus,
      nextStep,
      setErrors,
      errorHandlingErrors,
    ],
  );

  const getAvailablePostalCodesWithNpa = useCallback(
    async (npa: string) => {
      try {
        if (isNaN(+npa)) {
          return [];
        }

        return await postalCodeService.getPostalCodesWithNpa(+npa);
      } catch (error) {
        if (isErrorViewModel(error)) {
          setErrors([...errorHandlingErrors, error]);
        } else {
          setErrors([
            ...errorHandlingErrors,
            {
              statusCode: '',
              title: 'Unexpected error',
              value: {
                description: 'An unexpected error occurred. Please, contact the site administrator.',
              },
            },
          ]);
        }

        return [];
      }
    },
    [postalCodeService, isErrorViewModel, setErrors, errorHandlingErrors],
  );

  const validateForm = useCallback(
    (values: OrderFormValues) => {
      let errors: FormikErrors<OrderFormValues> = {};

      try {
        orderValidationSchema.validateSync(values, {
          abortEarly: false,
        });
      } catch (error) {
        errors = yupToFormErrors(error);
      }

      return errors;
    },
    [orderValidationSchema],
  );

  useEffect(() => {
    async function initWithPostalCodes(npa: string) {
      setIsLoading(true);

      const apcWithNpa = await getAvailablePostalCodesWithNpa(npa);
      setAvailablePostalCodesWithNpa([...availablePostalCodesWithNpa, ...apcWithNpa]);
      setIsLoading(false);
    }

    if (postalCode.npa) {
      initWithPostalCodes(postalCode.npa);
    }
  }, []);

  useEffect(() => {
    async function loadQuantitySettings() {
      setIsLoadingSettings(true);
      const maxFuel = await settingsService.getMaxFuelQuantity();
      const maxWood = await settingsService.getMaxWoodQuantity();
      const maxDiesel = await settingsService.getMaxDieselQuantity();
      const minFuel = await settingsService.getMinFuelQuantity();
      const minWood = await settingsService.getMinWoodQuantity();
      const minDiesel = await settingsService.getMinDieselQuantity();
      setMaxFuelQuantity(maxFuel);
      setMaxWoodQuantity(maxWood);
      setMaxDieselQuantity(maxDiesel);
      setMinFuelQuantity(minFuel);
      setMinWoodQuantity(minWood);
      setMinDieselQuantity(minDiesel);

      setIsLoadingSettings(false);
    }
    loadQuantitySettings();
  }, []);

  useEffect(() => {
    async function loadTextresources() {
      setIsLoadingTexResources(true);
      const minQuantityErrorTextResources = await textResourceService.getTextResources(
        language === 'fr' ? Language.FR : Language.DE,
        'MinimumQuantityErrorMessage',
      );
      const maxQuantityErrorTextResources = await textResourceService.getTextResources(
        language === 'fr' ? Language.FR : Language.DE,
        'MaximumQuantityErrorMessage',
      );
      const priceChangeInfoWoodTextResources = await textResourceService.getTextResources(
        language === 'fr' ? Language.FR : Language.DE,
        'DeliveryDate.PriceChangeInfo',
      );

      minQuantityErrorTextResources.map(({ key, value }) => {
        i18next.addResourceBundle(language, 'translation', { [key]: value }, false, true);
      });

      maxQuantityErrorTextResources.map(({ key, value }) => {
        i18next.addResourceBundle(language, 'translation', { [key]: value }, false, true);
      });

      priceChangeInfoWoodTextResources.map(({ key, value }) => {
        i18next.addResourceBundle(language, 'translation', { [key]: value }, false, true);
      });

      setIsLoadingTexResources(false);
    }
    loadTextresources();
  }, [language]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, [unloadingPlaceItems]);

  const handleNpaChange = async function (e) {
    if (
      e.currentTarget.value.length === 4 &&
      !availablePostalCodesWithNpa.some((apc) => apc.npa.toString() === e.currentTarget.value) // refresh list if npa is not the same as previous
    ) {
      try {
        const apcWithNpa = await getAvailablePostalCodesWithNpa(e.currentTarget.value);
        setAvailablePostalCodesWithNpa([...availablePostalCodesWithNpa, ...apcWithNpa]);

        if (apcWithNpa.length === 1) {
          return apcWithNpa[0].name;
        }
      } catch (error) {
        setErrors([
          ...errorHandlingErrors,
          {
            statusCode: '',
            title: 'Unexpected error',
            value: {
              description: 'An unexpected error occurred. Please, contact the site administrator.',
            },
          },
        ]);
      }
    }
    return availablePostalCodesWithNpa?.find((item) => item.npa.toString() === e?.currentTarget?.value)?.name;
  };

  if (isLoading || isLoadingSettings || isLoadingTexResources) {
    return (
      <div className="mt-2 d-flex justify-content-start">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">{t('Loading')}</span>
        </div>
      </div>
    );
  }

  return (
    <Formik
      onSubmit={(values, actions) => {
        submitOrderAndNextStep(values, actions);
      }}
      initialValues={initialValues}
      validationSchema={orderValidationSchema}
      validate={validateForm}
      validateOnMount={areQueryParamsPresent}
      initialTouched={{
        postalCode: areQueryParamsPresent
          ? {
              npa: !!orderQueryParams.postalCode,
              name: true,
            }
          : undefined,
        quantity: !!orderQueryParams.quantity,
        unloadingPlaces: true,
      }}
    >
      {({ handleSubmit, handleChange, setFieldValue, setFieldTouched, handleBlur, errors, touched, values }) => {
        return (
          <form noValidate onSubmit={handleSubmit}>
            <div className="py-3 col-lg-6 col-xl-4">
              <label htmlFor="postalCodeNpaId" className="form-label">
                <span>{t('PostalCode')}</span>
              </label>
              <Flex style={{ alignItems: 'center' }}>
                <Flex direction="column" style={{ flex: 1, position: 'relative' }}>
                  <input
                    className={`form-control ${touched.postalCode?.npa && !!errors.postalCode?.npa && 'is-invalid'}`}
                    id="postalCodeNpaId"
                    type="text"
                    name="postalCode.npa"
                    value={values.postalCode.npa}
                    onChange={async (e) => {
                      setFieldTouched('postalCode.npa', true, false);
                      handleChange(e);
                      const postalCodeLocationName = await handleNpaChange(e);
                      setFieldValue('postalCode.name', postalCodeLocationName, false);
                    }}
                    onBlur={handleBlur}
                    disabled={touched.postalCode?.npa && isVerifyingPostalCodeFieldName === 'postalCode.npa'}
                    required
                  />

                  <div className="invalid-tooltip">{errors.postalCode?.npa}</div>
                  {touched.postalCode?.npa && isVerifyingPostalCodeFieldName === 'postalCode.npa' && (
                    <div
                      className="position-absolute d-flex align-items-center"
                      style={{ right: '2rem', top: 0, bottom: 0 }}
                    >
                      <div className="spinner-border spinner-border-sm text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  )}
                </Flex>

                <img
                  src={TooltipSvg}
                  alt="Additional information tooltip"
                  className="mx-2"
                  data-tip={
                    type === FuelProductName
                      ? t('TooltipPostalCodeFuel')
                      : type === DieselProductName
                      ? t('TooltipPostalCodeDiesel')
                      : t('TooltipPostalCodeWood')
                  }
                />
              </Flex>
            </div>

            {availablePostalCodesWithNpa.filter((apc) => apc.npa.toString() === values.postalCode.npa).length > 1 && (
              <div className="py-3 col-lg-6 col-xl-4">
                <label htmlFor="postalCodeLocationId" className="form-label">
                  <span>{t('PostalCodeLocation')}</span>
                </label>
                <Flex style={{ alignItems: 'center' }}>
                  <Flex direction="column" style={{ flex: 1, position: 'relative' }}>
                    <select
                      id="postalCodeLocationId"
                      className={`form-select ${touched.postalCode?.name && !!errors.postalCode?.name && 'is-invalid'}`}
                      name="postalCode.name"
                      value={values.postalCode.name ?? ''}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                    >
                      <option value="" disabled hidden>
                        {t('ChoosePostalCodeLocation')}
                      </option>
                      {availablePostalCodesWithNpa
                        .filter((apc) => apc.npa.toString() === values.postalCode.npa)
                        .map((apc) => (
                          <option key={apc.id} value={apc.name}>
                            {apc.name}
                          </option>
                        ))}
                    </select>

                    <div className="invalid-tooltip">{errors.postalCode?.name}</div>
                  </Flex>

                  <img src={TooltipSvg} alt="Additional information tooltip" className="mx-2" />
                </Flex>
              </div>
            )}

            <div className="py-3 col-lg-6 col-xl-4">
              <label htmlFor="quantityId" className="form-label">
                <span>
                  {t('Quantity')} {type === FuelProductName || type === DieselProductName ? `(${t('Liters')})` : '(kg)'}
                </span>
              </label>
              <Flex style={{ alignItems: 'center' }}>
                <Flex direction="column" style={{ flex: 1, position: 'relative' }}>
                  <input
                    id="quantityId"
                    className={`form-control ${touched.quantity && !!errors.quantity && 'is-invalid'}`}
                    type="number"
                    step="100"
                    name="quantity"
                    value={values.quantity}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  />

                  <div className="invalid-tooltip">{errors.quantity}</div>
                </Flex>

                <img
                  src={TooltipSvg}
                  alt="Additional information tooltip"
                  className="mx-2"
                  data-tip={
                    type === FuelProductName
                      ? t('TooltipQuantityFuel')
                      : type === DieselProductName
                      ? t('TooltipPostalCodeDiesel')
                      : t('TooltipQuantityWood')
                  }
                />
              </Flex>
            </div>

            {type !== DieselProductName && (
              <div className="py-3 col-lg-6 col-xl-4">
                <label htmlFor="unloadingPlacesId" className="form-label">
                  <span>{t('UnloadingPlaces')}</span>
                </label>
                <Flex style={{ alignItems: 'center' }}>
                  <Flex direction="column" style={{ flex: 1, position: 'relative' }}>
                    <select
                      id="unloadingPlacesId"
                      className={`form-select ${touched.unloadingPlaces && !!errors.unloadingPlaces && 'is-invalid'}`}
                      name="unloadingPlaces"
                      value={values.unloadingPlaces}
                      onChange={(e) => {
                        Number(e.target.value) > 1 && alert(t('MultipleUnloadingPlacesInfo'));
                        let items: UnloadingPlace[] = [];

                        if (Number(e.target.value) > 1) {
                          items = Array.from({ length: Number(e.target.value) }).map<UnloadingPlace>(
                            (_item, index) => ({
                              npa: '',
                              quantity: 0,
                              name: '',
                            }),
                          );
                        }
                        setUnloadingPlaceItems(items);
                        setFieldValue('unloadingPlaceItems', items, false);
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      required
                    >
                      {[...Array(possibleUnloadingPlaces)].map((e, i) => (
                        <option key={i + 1} value={i + 1}>
                          {i + 1}
                        </option>
                      ))}
                    </select>

                    <div className="invalid-tooltip">{errors.unloadingPlaces}</div>
                  </Flex>

                  <img
                    src={TooltipSvg}
                    alt="Additional information tooltip"
                    className="mx-2"
                    data-tip={
                      type === FuelProductName ? t('TooltipUnloadingPlacesFuel') : t('TooltipUnloadingPlacesWood')
                    }
                  />
                </Flex>
              </div>
            )}

            {/* Unloading places items */}
            {values.unloadingPlaceItems.length > 0 &&
              type !== DieselProductName &&
              values.unloadingPlaceItems.map((unloadingPlace, index) => (
                <div key={index} className="py-3 col-lg-6 col-xl-4">
                  <label style={{ fontWeight: 'bold' }} className="form-label">
                    <span>{t('UnloadingPlace') + ' ' + (index + 1)}</span>
                  </label>

                  {/* Postalcode */}
                  <div>
                    <label htmlFor={`unloadingPlaceItems[${index}].npaId`} className="form-label">
                      <span>{t('PostalCode')}</span>
                    </label>
                    <Flex style={{ alignItems: 'center' }}>
                      <Flex direction="column" style={{ flex: 1, position: 'relative' }}>
                        <input
                          className={`form-control ${
                            touched.unloadingPlaceItems?.[index]?.npa &&
                            errors.unloadingPlaceItems &&
                            !!(errors.unloadingPlaceItems[index] as FormikErrors<UnloadingPlace>)?.npa &&
                            'is-invalid'
                          }`}
                          id={`unloadingPlaceItems[${index}].npaId`}
                          type="text"
                          name={`unloadingPlaceItems[${index}].npa`}
                          value={values.unloadingPlaceItems[index].npa}
                          onChange={async (e) => {
                            setFieldTouched(`unloadingPlaceItems[${index}].npa`, true, false);
                            handleChange(e);
                            const postalCodeLocationName = await handleNpaChange(e);
                            setFieldValue(`unloadingPlaceItems[${index}].name`, postalCodeLocationName, false);
                          }}
                          onBlur={handleBlur}
                          disabled={
                            touched.unloadingPlaceItems?.[index]?.npa &&
                            isVerifyingPostalCodeFieldName === `unloadingPlaceItems[${index}].npa`
                          }
                          required
                        />
                        {errors.unloadingPlaceItems && (
                          <div className="invalid-tooltip">
                            {(errors.unloadingPlaceItems?.[index] as FormikErrors<UnloadingPlace>)?.npa}
                          </div>
                        )}
                      </Flex>
                      <img
                        src={TooltipSvg}
                        alt="Additional information tooltip"
                        className="mx-2"
                        data-tip={type === FuelProductName ? t('TooltipPostalCodeFuel') : t('TooltipPostalCodeWood')}
                      />
                    </Flex>
                  </div>
                  {/* Location's name */}
                  {availablePostalCodesWithNpa.filter(
                    (apc) => apc.npa.toString() === values.unloadingPlaceItems[index].npa,
                  ).length > 1 && (
                    <div className="py-3 col-lg-6 col-xl-4">
                      <label htmlFor="postalCodeLocationId" className="form-label">
                        <span>{t('PostalCodeLocation')}</span>
                      </label>
                      <Flex style={{ alignItems: 'center' }}>
                        <Flex direction="column" style={{ flex: 1, position: 'relative' }}>
                          <select
                            id="postalCodeLocationId"
                            className={`form-select ${
                              touched.unloadingPlaceItems?.[index]?.name &&
                              (errors?.unloadingPlaceItems?.[index] as FormikErrors<UnloadingPlace>)?.name &&
                              'is-invalid'
                            }`}
                            name={`unloadingPlaceItems[${index}].name`}
                            value={values.unloadingPlaceItems[index].name ?? ''}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                          >
                            <option value="" disabled hidden>
                              {t('ChoosePostalCodeLocation')}
                            </option>
                            {availablePostalCodesWithNpa
                              .filter((apc) => apc.npa.toString() === values.unloadingPlaceItems[index].npa)
                              .map((apc) => (
                                <option key={apc.id} value={apc.name}>
                                  {apc.name}
                                </option>
                              ))}
                          </select>

                          <div className="invalid-tooltip">
                            {(errors?.unloadingPlaceItems?.[index] as FormikErrors<UnloadingPlace>)?.name}
                          </div>
                        </Flex>

                        <img src={TooltipSvg} alt="Additional information tooltip" className="mx-2" />
                      </Flex>
                    </div>
                  )}

                  {/* Quantity */}
                  <div>
                    <label htmlFor={`unloadingPlaceItems[${index}].quantityId`} className="form-label">
                      <span>
                        {t('Quantity')} {type === FuelProductName ? `(${t('Liters')})` : '(kg)'}
                      </span>
                    </label>
                    <Flex style={{ alignItems: 'center' }}>
                      <Flex direction="column" style={{ flex: 1, position: 'relative' }}>
                        <input
                          id={`unloadingPlaceItems[${index}].quantityId`}
                          className={`form-control ${
                            touched.unloadingPlaceItems &&
                            touched.unloadingPlaceItems[index] &&
                            errors.unloadingPlaceItems &&
                            !!(errors.unloadingPlaceItems[index] as FormikErrors<UnloadingPlace>)?.quantity &&
                            'is-invalid'
                          }`}
                          type="number"
                          step="100"
                          name={`unloadingPlaceItems[${index}].quantity`}
                          value={values.unloadingPlaceItems[index].quantity}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          required
                        />
                        {errors.unloadingPlaceItems && (
                          <div className="invalid-tooltip">
                            {(errors.unloadingPlaceItems?.[index] as FormikErrors<UnloadingPlace>)?.quantity}
                          </div>
                        )}
                      </Flex>
                      <img
                        src={TooltipSvg}
                        alt="Additional information tooltip"
                        className="mx-2"
                        data-tip={type === FuelProductName ? t('TooltipQuantityFuel') : t('TooltipQuantityWood')}
                      />
                    </Flex>
                  </div>
                </div>
              ))}

            <div className="col-sm col-md-3">
              <button className="btn btn-primary mt-3" type="submit">
                <span>{t('OrderFormSubmit')}</span>
              </button>
            </div>

            <ReactTooltip className="tooltip" place="right" multiline effect="solid" />
          </form>
        );
      }}
    </Formik>
  );
};

export default withErrorHandling(OrderForm);
