import {
  useAlertService,
  useApplicationStatus,
  useOrderFormContext,
  usePostalCodeService,
  useStepper,
} from '../../../../hooks';
import Step from '../../../stepper/step/Step';
import { Flex } from '../../../../styled-components';
import { useTranslation } from 'react-i18next';
import { WoodProductName, DieselProductName } from '../../../../assets/constants/Constants';
import { Formik } from 'formik';
import { useMemo } from 'react';
import * as Yup from 'yup';
import { Form } from 'react-bootstrap';
import withErrorHandling from '../../../common/hoc/with-error-handling/withErrorHandling';
import useCurrentLanguage from '../../../../hooks/useCurrentLanguage';
import { Alert, IApplicationStatus, isErrorViewModel } from '../../../../models';
import useErrorHandling from '../../../../hooks/useErrorHandling';
import OrderStepperContext from '../../../../contexts/stepper/OrderStepperContext';
import Configurator from './configurator/Configurator';
import DesiredPrice from './desired-price/DesiredPrice';
import PriceSubscription from './regular-interval-notification/PriceSubscription';
import PriceAlarm from './price-alarm/PriceAlarm';
import { Language, UnitCode } from '../../../../assets/enums';
import capitalizeFirstLetter from '../../../../utils/capitalizeFirstLetter';
import moment from 'moment';
import { Title } from '../../../../contexts';
import NewsletterSubscription from './newsletter-subscription/NewsletterSubscription';
import StepHeader from '../../../common/step-header/StepHeader';

// type RequiredFields<T> = {
//   [K in keyof T as T[K] extends Required<T>[K] ? K : never]: T[K]
// }

/**
 * The form values.
 */
export interface PriceInformationFormValues {
  /**
   * The input email from the user.
   */
  email: string;

  /**
   * Whether the user set up the desired price.
   * Used in the validation schema in order to make certain validations
   * required in case of chosen option.
   */
  useDesiredPrice: boolean;

  /**
   * The desired strike price.
   */
  desiredPrice?: number | string;

  /**
   * The expiration of the desired strike price.
   * String because the option in the select tag must be a string.
   * This string is constructed from the Expiration enum.
   */
  desiredPriceExpiration?: string;

  /**
   * Whether the user set up the desired price.
   * Used in the validation schema in order to make certain validations
   * required in case of chosen option.
   */
  usePriceAlarm: boolean;

  /**
   * The desired strike price.
   */
  priceAlarm?: number | string;

  /**
   * The expiration of the desired strike price.
   * String because the option in the select tag must be a string.
   * This string is constructed from the Expiration enum.
   */
  priceAlarmExpiration?: string;

  /**
   * Whether the user set up the price subscription.
   * Used in the validation schema in order to make certain validations
   * required in case of chosen option.
   */
  usePriceSubscription: boolean;

  /**
   * The desired price subscription notifcation interval.
   */
  priceSubscription?: number | string;

  /**
   * Whether the user set up the newsletter subscription.
   * Used in the validation schema in order to make certain validations
   * required in case of chosen option.
   */
  useNewsletter: boolean;

  /**
   * The title selected for the newsletter subscription.
   */
  newsletterTitle?: Title;

  /**
   * The name input for the newsletter subscription.
   */
  newsletterName?: string;

  /**
   * The surnname input for the newsletter subscription.
   */
  newsletterSurname?: string;
}

function PriceInformationStep() {
  const { t } = useTranslation();
  const language = useCurrentLanguage();

  const alertService = useAlertService();
  const postalCodeService = usePostalCodeService();

  const { errors, setErrors } = useErrorHandling();

  const { setApplicationStatus } = useApplicationStatus();

  const {
    postalCode,
    quantity,
    unloadingPlaces,
    priceDetails,
    email,
    shop,
    selectedProduct,

    useDesiredPrice,
    setUseDesiredPrice,
    desiredPrice,
    desiredPriceExpiration,

    usePriceAlarm,
    setUsePriceAlarm,
    priceAlarm,
    priceAlarmExpiration,

    usePriceSubscription,
    setUsePriceSubscription,
    priceSubscription,

    useNewsletter,
    setUseNewsletter,
    title,
    setTitle,
    name,
    setName,
    surname,
    setSurname,
  } = useOrderFormContext();

  const { prevStep } = useStepper(OrderStepperContext)!;

  /**
   * Callback that performs validation and sets the input form data
   * in the application status.
   * If everything is validated and no errors arise, it goes onto the next step.
   */
  const submitAndNextStep = async (values: PriceInformationFormValues) => {
    const { newsletterName, newsletterSurname, newsletterTitle, ...restValues } = values;

    setTitle(newsletterTitle);
    setName(newsletterName!);
    setSurname(newsletterSurname!);

    const newValues: IApplicationStatus = {
      ...restValues,
      desiredPrice: values.desiredPrice ? Number(values.desiredPrice) : undefined,
      desiredPriceExpiration: values.desiredPriceExpiration ? Number(values.desiredPriceExpiration) : undefined,

      priceAlarm: values.priceAlarm ? Number(values.priceAlarm) : undefined,
      priceAlarmExpiration: values.priceAlarmExpiration ? Number(values.priceAlarmExpiration) : undefined,

      priceSubscription: values.priceSubscription ? Number(values.priceSubscription) : undefined,

      name: newsletterName,
      surname: newsletterSurname,
      title: newsletterTitle,
    };

    setApplicationStatus(newValues);

    try {
      if (isNaN(+postalCode.npa)) {
        return;
      }

      const completePostalCode = await postalCodeService.getPostalCodeByNpaAndLocation(
        parseInt(postalCode.npa),
        postalCode.name,
      );

      const requiredFields: Alert = {
        unitCode:
          selectedProduct!.productType.productName === WoodProductName
            ? UnitCode.kg
            : selectedProduct!.productType.productName === DieselProductName
            ? UnitCode.lt
            : UnitCode.lt,
        language: language === 'de' ? Language.DE : Language.FR,
        portal: '',
        postalCode: postalCode!.npa.toString(),
        deliveryDifficultyFeeCode: completePostalCode.deliveryDifficultyFeeCode,
        productId: selectedProduct!.id,
        productName: selectedProduct!['name' + capitalizeFirstLetter(language)],
        quantity: quantity,
        shopId: shop!.id,
        shopContact: shop!.address,
        subscriberEmail: newValues.email!,
        unloadingPlaces: unloadingPlaces,
        zoneId: priceDetails!.zone.id,
        zoneName: priceDetails!.zone.name,
      };

      let subscribed = false;

      if (newValues.useDesiredPrice) {
        const desiredPriceRequest: Alert = {
          ...requiredFields,
          desiredPrice: newValues.desiredPrice!,
          historicPrice: priceDetails!.unitPrice,
          priceValidUntil: moment().add(Number(newValues.desiredPriceExpiration), 'days').toDate(),
        };

        await alertService.setDesiredPrice(desiredPriceRequest);
      }

      if (newValues.usePriceAlarm) {
        const priceAlarmRequest: Alert = {
          ...requiredFields,
          desiredPrice: newValues.priceAlarm!,
          historicPrice: priceDetails!.unitPrice,
          priceValidUntil: moment().add(Number(newValues.priceAlarmExpiration), 'days').toDate(),
        };

        await alertService.setPriceAlarm(priceAlarmRequest);
      }

      if (newValues.usePriceSubscription) {
        const priceSubscriptionRequest: Alert = {
          ...requiredFields,
          interval: newValues.priceSubscription,
        };

        await alertService.setPriceSubscription(priceSubscriptionRequest);
      }

      if (newValues.useNewsletter) {
        const newsletterSubscriptionRequest: Alert = {
          ...requiredFields,
        };

        await alertService.setNewsletterSubscription(newsletterSubscriptionRequest);
      }

      subscribed =
        !!newValues.useDesiredPrice ||
        !!newValues.useNewsletter ||
        !!newValues.usePriceAlarm ||
        !!newValues.usePriceSubscription;

      if (subscribed) {
        alert(t('PriceInformation.SuccessfullySubscribedMessage'));
      }
    } catch (error) {
      console.error(error);

      if (isErrorViewModel(error)) {
        setErrors([...errors, error]);
      } else {
        setErrors([
          ...errors,
          {
            title: (error as Error).message,
            statusCode: '',
            value: {
              description: (error as Error).message,
            },
          },
        ]);
      }

      return;
    }
  };

  const initialValues = useMemo<PriceInformationFormValues>(
    () => ({
      email: email,

      useDesiredPrice: useDesiredPrice,
      desiredPrice: desiredPrice?.toString() ?? '',
      desiredPriceExpiration: desiredPriceExpiration.toString(),

      usePriceAlarm: usePriceAlarm,
      priceAlarm: priceAlarm?.toString() ?? '',
      priceAlarmExpiration: priceAlarmExpiration.toString(),

      usePriceSubscription: usePriceSubscription,
      priceSubscription: priceSubscription.toString(),

      useNewsletter: useNewsletter,
      newsletterTitle: title,
      newsletterName: name ?? '',
      newsletterSurname: surname ?? '',
    }),
    [],
  );

  const priceInformationValidationSchema = useMemo(
    () =>
      Yup.object().shape({
        email: Yup.string()
          .matches(
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
            t('PersonalData.ValidationErrors.InvalidEmail'),
          )
          .required(t('PersonalData.ValidationErrors.Email')),
        useDesiredPrice: Yup.boolean().required(),
        desiredPrice: Yup.number().when('useDesiredPrice', {
          is: true,
          then: Yup.number()
            .typeError(t('PriceInformation.Configurators.DesiredPrice.ValidationErrors.DesiredPriceTypeError'))
            .required(t('PriceInformation.Configurators.DesiredPrice.ValidationErrors.DesiredPriceRequired')),
          otherwise: Yup.number().typeError(
            t('PriceInformation.Configurators.DesiredPrice.ValidationErrors.DesiredPriceTypeError'),
          ),
        }),
        desiredPriceExpiration: Yup.number(),

        usePriceAlarm: Yup.boolean().required(),
        priceAlarm: Yup.number().when('usePriceAlarm', {
          is: true,
          then: Yup.number()
            .typeError(t('PriceInformation.Configurators.PriceAlarm.ValidationErrors.PriceAlarmTypeError'))
            .required(t('PriceInformation.Configurators.PriceAlarm.ValidationErrors.PriceAlarmRequired')),
          otherwise: Yup.number().typeError(
            t('PriceInformation.Configurators.PriceAlarm.ValidationErrors.PriceAlarmTypeError'),
          ),
        }),
        priceAlarmExpiration: Yup.number(),

        usePriceSubscription: Yup.boolean().required(),
        priceSubscription: Yup.number().when('usePriceSubscription', {
          is: true,
          then: Yup.number().required(
            t('PriceInformation.Configurators.RegularIntervalNotification.ValidationErrors.PriceSubscriptionRequired'),
          ),
          otherwise: Yup.number(),
        }),

        useNewsletter: Yup.boolean().required(),
        newsletterTitle: Yup.string().when('useNewsletter', {
          is: true,
          then: Yup.string().required(t('PriceInformation.Configurators.Newsletter.ValidationErrors.TitleRequired')),
          otherwise: Yup.string(),
        }),
        newsletterName: Yup.string().when('useNewsletter', {
          is: true,
          then: Yup.string().required(t('PriceInformation.Configurators.Newsletter.ValidationErrors.NameRequired')),
          otherwise: Yup.string(),
        }),
        newsletterSurname: Yup.string().when('useNewsletter', {
          is: true,
          then: Yup.string().required(t('PriceInformation.Configurators.Newsletter.ValidationErrors.SurnameRequired')),
          otherwise: Yup.string(),
        }),
      }),
    [t],
  );

  return (
    <Step>
      <Flex direction="column" className="justify-content-between align-items-start">
        <StepHeader />

        <Formik
          onSubmit={submitAndNextStep}
          initialValues={initialValues}
          validationSchema={priceInformationValidationSchema}
        >
          {({ handleSubmit, handleChange, setFieldValue, values, errors, touched, isSubmitting }) => {
            return (
              <Form noValidate onSubmit={handleSubmit} className="align-self-stretch">
                <span className="d-block mb-4">{t('PriceInformation.LetUsInformYou')}</span>
                <button className="d-block btn btn-secondary mb-4" type="button" onClick={() => prevStep()}>
                  <span>{'< ' + t('PriceInformation.BackButton')}</span>
                </button>

                <div className="mb-4">
                  {touched.email && errors.email && <span className="d-block text-danger">{errors.email}</span>}
                  <label htmlFor="emailId" className="form-label">
                    <span>{t('PriceInformation.Email')}</span>
                  </label>
                  <input
                    id="emailId"
                    type="text"
                    className="form-control"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    style={{ width: '75%' }}
                  />
                </div>

                <Configurator
                  title={t('PriceInformation.Configurators.DesiredPrice.Title')}
                  subtitle={
                    <span
                      dangerouslySetInnerHTML={{ __html: t('PriceInformation.Configurators.DesiredPrice.Subtitle') }}
                    />
                  }
                  showConfigurator={useDesiredPrice}
                  setShowConfigurator={(e) => {
                    const newDesiredPrice = e.currentTarget.value === 'true';

                    setUseDesiredPrice(newDesiredPrice);
                    setFieldValue('useDesiredPrice', newDesiredPrice, false);
                  }}
                  component={
                    <DesiredPrice
                      actualPrice={priceDetails!.unitPrice}
                      values={values}
                      errors={errors}
                      handleChange={handleChange}
                    />
                  }
                />
                <Configurator
                  title={t('PriceInformation.Configurators.PriceAlarm.Title')}
                  subtitle={
                    <span
                      dangerouslySetInnerHTML={{ __html: t('PriceInformation.Configurators.PriceAlarm.Subtitle') }}
                    />
                  }
                  showConfigurator={usePriceAlarm}
                  setShowConfigurator={(e) => {
                    const newPriceAlarm = e.currentTarget.value === 'true';

                    setUsePriceAlarm(newPriceAlarm);
                    setFieldValue('usePriceAlarm', newPriceAlarm, false);
                  }}
                  component={
                    <PriceAlarm
                      actualPrice={priceDetails!.unitPrice}
                      values={values}
                      errors={errors}
                      handleChange={handleChange}
                    />
                  }
                />
                <Configurator
                  title={t('PriceInformation.Configurators.RegularIntervalNotification.Title')}
                  subtitle={
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t('PriceInformation.Configurators.RegularIntervalNotification.Subtitle'),
                      }}
                    />
                  }
                  showConfigurator={usePriceSubscription}
                  setShowConfigurator={(e) => {
                    const newPriceSubscription = e.currentTarget.value === 'true';

                    setUsePriceSubscription(newPriceSubscription);
                    setFieldValue('usePriceSubscription', newPriceSubscription, false);
                  }}
                  component={<PriceSubscription values={values} errors={errors} handleChange={handleChange} />}
                />
                {priceDetails!.zone.newsletter && (
                  <Configurator
                    title={t('PriceInformation.Configurators.Newsletter.Title')}
                    subtitle={
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t('PriceInformation.Configurators.Newsletter.Subtitle'),
                        }}
                      />
                    }
                    showConfigurator={useNewsletter}
                    setShowConfigurator={(e) => {
                      const newNewsletterSubscription = e.currentTarget.value === 'true';

                      setUseNewsletter(newNewsletterSubscription);
                      setFieldValue('useNewsletter', newNewsletterSubscription, false);
                    }}
                    component={<NewsletterSubscription values={values} errors={errors} handleChange={handleChange} />}
                  />
                )}

                <button className="mt-4 btn btn-primary" type="submit" disabled={isSubmitting}>
                  {isSubmitting ? (
                    <div className="spinner-border spinner-border-sm text-light" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  ) : (
                    <span>{t('PriceInformation.NextButton')}</span>
                  )}
                </button>
              </Form>
            );
          }}
        </Formik>
      </Flex>
    </Step>
  );
}

export default withErrorHandling(PriceInformationStep);
